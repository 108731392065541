.dropMenuModule{
    
    .dropMenu{
        width:100%;
        position: absolute;
        z-index: 110;
        .dropMenuList{
            min-width: fit-content;
            &.center{
                left: 50%;
                transform: translateX(-50%);
            }
            &.right{
                // right: 300px;
                left: 100%;
                transform: translateX(-100%);
            }
        }
    }

    .effect_down {
        transform: translateY(60px) scale(0);
        opacity: 0;
        filter: blur(50px);
        transition: all 0.3s;
        transition-delay: 0.1s;
    }
    &.hover:hover .effect_down, 
    &.click .effect_down{
        transform: translateY(0) scale(1);
        opacity: 1;
        filter: blur(0);        
    }

    .effect_up {
        top: -10px;
        transform: translateY(0) scale(0);
        opacity: 0;
        filter: blur(50px);
        transition: all 0.3s;
        transition-delay: 0.1s;
    }
    &.hover:hover .effect_up, 
    &.click .effect_up{
        transform: translateY(-100%) scale(1);
        opacity: 1;
        filter: blur(0);
        // animation: dropMenu-top .5s;
        // animation: dropMenu-top .3s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        // animation-delay: 0.5s;
        
    }
    

}


@keyframes dropMenu-top {
    from {
      transform: translateY(100px) scale(1);
      transform-origin: 50% 0%;
      filter: blur(40px);
      opacity: 0;
    }
    to {
      transform: translateY(0) scale(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
}

@keyframes dropMenu-down {
    from {
        transform: scale(1);
        filter: blur(0);
        opacity: 1;
    }
    to {
        transform: scale(0);
        filter: blur(40px);
        opacity: 0;
    }
    
}