// 231221 del selectBox.z-index:10;
.selectBox{
    position:relative;
    user-select: none;
    flex-shrink: 0;
    .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    .selectBoxList{
        overflow:hidden;
        position: absolute;
        white-space:nowrap;
        overflow-y: auto;
        z-index: 100;
        transition: top 0.2s,opacity 0.2s;
        .row{
            cursor: pointer;
        }
    }

    .selectBoxIcon{
        line-height: 100%;
    }

    .selectOption{
        cursor: pointer;
        opacity: 0.7;
        &.active{
            opacity: 1;
            font-weight: 700;
        }
        &:hover{
            opacity: 1;
        }
    }

}
