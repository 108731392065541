
.menus_title{
    .icon{
        transition: all 0.2s;
    }
}

.menus_box{
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.3s;

    &.close{
        max-height: 0;
        overflow: hidden;
    }
}
