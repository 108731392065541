
$screens: (
    '': "",
    's': (240px, 768px),
    'm': (768px, 1200px),
    'l': (1200px),
);

@media screen and (max-width: 768px){
    .container{
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
    }
    .containerIn{
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px){
    .container{
        padding-left: 24px;
        padding-right: 24px;
        width: 1200px;
    }
    .containerIn{
        padding-left: 24px;
        padding-right: 24px;
        width: 1200px;
    }
}
@media screen and (min-width: 1200px){
    .container{
        padding-left: 24px;
        padding-right: 24px;
        width: 1200px;
    }
    .containerIn{
        padding-left: 24px;
        padding-right: 24px;
        width: 1480px;
    }
}


// padding,margin
$spacing: -2,0,2,4,6,8,12,16,20,24,32,36,40,48,56,64,96,118,128,148,168;
$numbers: 0,1,2,4,6,8,10,12,20,22,24,26,28,32,36,40,48,50,56,60,64,72,84,90,96,100,118,120,128,148,150,160,180,200,240,300,320,360,400,500,560,600,700,800,900,960,1080,1400,1900;

/* 抽出媒体查询逻辑，传入设备名称，并通过 content 接收具体内容 */
@mixin autoscreen($device) {
    @if $device == "" {
        @content;
    }
    /* 通过 map-get 获取对应的设备宽度信息 */
    $width: map-get($screens, $device);
    @if type-of($width) == 'list' {
        @media screen and (min-width: nth($width, 1)) and (max-width: nth($width, 2)) {
            @content;
        }
    } @else {
        @media screen and (min-width: $width) {
            @content;
        }
    }
}


@each $key,$value in $screens {
    @include autoscreen(#{$key}) {
        .#{$key}d_hide{
            display: none;
        }

        // width percent w100,h50 ,,,,
        @each $number in $numbers {
            .#{$key}ws_#{$number} {width: $number * 1px;}
            .#{$key}hs_#{$number} {height: $number * 1px;}
            .#{$key}wmax_#{$number} {max-width: $number * 1px;;}
            .#{$key}wmin_#{$number} {min-width: $number * 1px;;}
            .#{$key}hmin_#{$number} {min-height: $number * 1px;;}
            .#{$key}hmax_#{$number} {max-height: $number * 1px;;}
            

            .#{$key}w#{$number} {width: $number * 1%;}
            .#{$key}h#{$number} {height: $number * 1%;}

            .#{$key}p_top_#{$number} { top: $number * 1px; }
            .#{$key}p_bottom_#{$number} { bottom: $number * 1px;}
            .#{$key}p_left_#{$number} { left: $number * 1px; }
            .#{$key}p_right_#{$number} { right: $number * 1px; }
            .#{$key}np_right_#{$number} { right: $number * -1px; }
            
        }

        // font-size [++2]
        @for $i from 5 through 96 {
            .#{$key}fs_#{$i * 2} {font-size: $i * 2px !important;}
        }

        // text 
        .#{$key}text_center{ text-align: center; }
        .#{$key}text_left{ text-align: left; }
        .#{$key}text_right{ text-align: right; }

        // border radius [++4]
        @for $i from 0 through 12 {
            .#{$key}br_#{$i * 4} { border-radius: $i * 4px !important; }
            .#{$key}br_t_#{$i * 4} { border-radius: $i * 4px $i * 4px 0 0 !important; }
            .#{$key}br_b_#{$i * 4} { border-radius: 0 0 $i * 4px $i * 4px !important; }
        }
        .#{$key}br_100{ border-radius: 100vh; }
        .#{$key}br_t_100{ border-radius: 100vh 100vh 0 0 !important; }

        @for $i from 0 through 8 {
            .#{$key}bd_w_#{$i}{ border-width: #{$i}px !important; }
        }
        
        // padding, margin
        @each $count in $spacing {

            .#{$key}pa_#{$count} {
                padding: #{$count}px !important;
            }
            .#{$key}pl_#{$count} {
                padding-left: #{$count}px !important;
            }
            .#{$key}pr_#{$count} {
                padding-right: #{$count}px !important;
            }
            .#{$key}pt_#{$count} {
                padding-top: #{$count}px !important;
            }
            .#{$key}pb_#{$count} {
                padding-bottom: #{$count}px !important;
            }
            .#{$key}px_#{$count} {
                padding-left: #{$count}px !important;
                padding-right: #{$count}px !important;
            }
            .#{$key}py_#{$count} {
                padding-top: #{$count}px !important;
                padding-bottom: #{$count}px !important;
            }
        
            .#{$key}ml_#{$count} {
                margin-left: #{$count}px !important;
            }
            .#{$key}mr_#{$count} {
                margin-right: #{$count}px !important;
            }
            .#{$key}mt_#{$count} {
                margin-top: #{$count}px !important;
            }
            .#{$key}mb_#{$count} {
                margin-bottom: #{$count}px !important;
            }
            .#{$key}my_#{$count} {
                margin-top: #{$count}px !important;
                margin-bottom: #{$count}px !important;
            }
        }

        // scale
        @for $i from 0 through 9 {
            .#{$key}scale_#{$i} { transform: scale($i*0.1) }
        }

        // grid
        .#{$key}grid{ display:grid; }

        @for $i from 1 through 10 {
            .#{$key}g_r_#{$i}{ grid-template-columns: repeat($i,1fr); }
        }
        .#{$key}g_r_3_a{ grid-template-columns: 40% 30% auto; }
        .#{$key}g_r_3_b{ grid-template-columns: 40% 35% auto; }
        .#{$key}g_r_3_c{grid-template-columns: 50% 20% auto;}
        .#{$key}g_r_4_a{ grid-template-columns: repeat(3,26%) auto; }
        .#{$key}g_r_4_b{ grid-template-columns: 32% 26% 20% auto; }
        .#{$key}g_r_5_a{ grid-template-columns: repeat(3,24%) 14% auto; }
        .#{$key}g_r_5_b{ grid-template-columns: repeat(3,22%) 14% auto; }
        .#{$key}g_r_6_a{ grid-template-columns: repeat(4,16%) 12% auto; }
        .#{$key}g_r_7_a{ grid-template-columns: repeat(6,15%) 10%; }
        .#{$key}g_r_7_a{ grid-template-columns: repeat(6,13.5%) auto; }
        .#{$key}g_r_8_a{ grid-template-columns: repeat(7,10%) auto; }
        .#{$key}g_r_8_c{ grid-template-columns: 10% 10% 7% 9% 12% 9% 9% auto; }

        // gap_
        @each $count in $spacing {
            .#{$key}gap_#{$count} { gap: #{$count}px; }
        }


        // flex layout
        .#{$key}flex{ display: flex; }

        @for $i from 1 through 10 {
            .#{$key}flex_#{$i}{ flex: $i; }
        }

        .#{$key}flex_shrink{ flex-shrink: 0; }
        .#{$key}flex_wrap{ flex-wrap: wrap; }
        .#{$key}column{ flex-direction: column; }
        .#{$key}column_reverse{ flex-direction: column-reverse; }
        .#{$key}row_reverse{ flex-direction: row-reverse; }

        .#{$key}align_center{ align-items: center; }
        .#{$key}align_start{ align-items: flex-start; }
        .#{$key}align_end{ align-items: flex-end; }

        .#{$key}justify_center{ justify-content: center; }
        .#{$key}justify_between{ justify-content: space-between; }
        .#{$key}justify_around{ justify-content: space-around; }
        .#{$key}justify_end { justify-content: flex-end; }


        
    }
}



// font weight
@for $i from 2 through 7 {
    .fw_#{$i}00 {font-weight: $i * 100;}
}

// Opacity 0-1
@for $i from 0 through 10 {
    .opacity_#{$i}{opacity: $i / 10;}
}


.m_a_x { margin: 0 auto !important; }
.block{ display: block; }
.inline_block{ display: inline-block !important; }
.border_box{box-sizing: border-box;}

.overflow_hidden { overflow: hidden; }
.overflowy{ overflow-y: clip;}
.overflow_auto { overflow: auto; }




.text_capitalize { text-transform: capitalize; }
.text_upper { text-transform: uppercase; }
.text_underline{ text-decoration: underline !important; text-decoration-color: white; }

.t_es { text-overflow: ellipsis; overflow: hidden; }
.t_es_1{ text-overflow: -o-ellipsis-lastline; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1; -webkit-box-orient: vertical; }
.t_es_2{ text-overflow: -o-ellipsis-lastline; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical; }
.t_es_3{ text-overflow: -o-ellipsis-lastline; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical; }

.white_space_nowrap { white-space: nowrap; }
.break_word { word-wrap: break-word; }
.break_all { word-break: break-all; }
.select_none{ user-select: none; }


// line-height
.lh_1x{line-height: 1 !important;}
.lh_1x2{line-height: 1.2 !important;}
.lh_2x{line-height: 2 !important;}



// position layout
.p_relative{ position: relative; }
.p_absolute{ position: absolute;}
.p_fixed{ position: fixed; }
.p_sticky{ position: sticky; }
.p_inset{ inset: 0; }
.p_top { top: 0 }
.p_bottom { bottom: 0}
.p_left { left: 0 }
.p_right { right: 0 }


.zindex_low{ z-index: -1; }
.zindex_0{ z-index: 0; }
.zindex_1{ z-index: 1; }
.zindex_100{ z-index: 100; }
.zindex_1000{ z-index: 1000; }


.cursor{ cursor: pointer; }
.cursor_not{ cursor: not-allowed !important; }


.scroll_y { scrollbar-width: none; -ms-overflow-style: none; overflow-y: scroll; -webkit-overflow-scrolling: touch; }
// .scroll_y::-webkit-scrollbar { display: none; }
.scroll_x { scrollbar-width: none; -ms-overflow-style: none; overflow-x: scroll; -webkit-overflow-scrolling: touch; }
// .scroll_x::-webkit-scrollbar { display: none; }


.transition_2{ transition: 0.2s all; }


.display_none{
    display:none;
}