.loadingModule{
    display: flex;
    .radi1,.radi2,.radi3,.radi4{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 6px;
        cursor: pointer;
        flex-shrink: 0;
        animation: stretchdelay 1s infinite ease-in-out;
    }
    .radi1{
        animation-delay:-1.1s
    }
    .radi2{
        animation-delay:-0.9s
    }
    .radi3{
        animation-delay:-0.7s
    }
    .radi4{
      animation-delay:-0.5s
    }
    @keyframes stretchdelay {
        0%, 40%, 100% {
          transform: scale(0.6);

        }  20% {
          transform: scale(1.0);

        }
      }
}

.loading-text-animated {
    position: relative;
    flex-wrap: nowrap;

    -webkit-box-reflect: below -10px linear-gradient(transparent, rgba(0, 0, 0, 0.2));

    span {
        position: relative;
        display: inline-block;
        font-size: 14px;
        animation: jump-text 1s ease-in-out infinite;
        animation-delay: calc(.1s*var(--i));
    }

    @keyframes jump-text {
        0% {
            transform: translateY(0px)
        }

        20% {
            transform: translateY(-2px)
        }

        40%,
        100% {
            transform: translateY(0px)
        }
    }
}


.loading-spinner {
    border: 3px solid transparent;
    border-top-color: #ffffff66;
    border-left-color: #ffffff66;
    border-bottom-color: #ffffff66;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}